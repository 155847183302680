import { PanelPlugin } from '@grafana/data';
import { VoyageSelecterOptions } from './types';
import { VoyageSelecter } from './VoyageSelecter';

export const plugin = new PanelPlugin<VoyageSelecterOptions>(VoyageSelecter).setPanelOptions((builder) =>
  builder
    .addTextInput({
      path: 'caption',
      name: 'Caption',
      description: 'Caption',
      defaultValue: 'Voyages',
    })
    .addTextInput({
      path: 'label',
      name: 'Label',
      description: 'Label field',
      defaultValue: 'label',
    })
);
