import React, { useState, useEffect } from 'react';
import { PanelProps, SelectableValue } from '@grafana/data';
import { VoyageSelecterOptions } from 'types';

import { Label, Select, VerticalGroup } from '@grafana/ui';
import { getLocationSrv } from '@grafana/runtime';
import _ from 'lodash';

interface Props extends PanelProps<VoyageSelecterOptions> {}

export function onChange(fromDate: number, toDate: number) {
  const query = {
    [`from`]: fromDate,
    [`to`]: toDate,
  };
  getLocationSrv().update({
    partial: true,
    replace: true,
    query,
  });
}

const makeVoyageList = (series: any[], defaultOption: string): any[] => {
  const g = _.groupBy(_.head(series).fields, 'name');
  const label = g?.label?.[0].values?.toArray();
  const start = g?.start?.[0].values?.toArray();
  const end = g?.end?.[0].values?.toArray();
  if (label && start && end) {
    const voyageList = _.zip(label, start, end).map(
      ([label, start, end], value) => ({ label, start, end, value: value + 1 })
    );
  
    return [{ label: defaultOption, value: 0 }, ...voyageList];
  }
  return [{ label: defaultOption, value: 0 }];
};

const getValue = (voyageList: any, fromDate: any, toDate: any): number => {
  return voyageList.find((o: any) => o.start === fromDate.valueOf() && o.end === toDate.valueOf())?.value ?? 0;
};

export const VoyageSelecter: React.FC<Props> = (props) => {
  const {
    options,
    data: {
      series,
      timeRange: { from, to },
    },
  } = props;
  const [value, setValue] = useState<number>();
  const [voyageList, setSetVoyageList] = useState<SelectableValue[]>([]);

  useEffect(() => {
    const voyageList = makeVoyageList(series, options.caption);
    setSetVoyageList(voyageList);
    setValue(getValue(voyageList, from, to));
  }, [series, from, to, options.caption]);

  return (
    <VerticalGroup>
      <Label>Voyage Time Ranges</Label>
      <Select
        options={voyageList}
        value={value}
        allowCustomValue
        onChange={(change) => {
          onChange(+change.start, +change.end);
        }}
      />
    </VerticalGroup>
  );
};
